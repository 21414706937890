/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */

.blog-fonts-loaded code[class*="language-"],
.blog-fonts-loaded pre[class*="language-"] {
	font-family: 'Operator Mono', monospace;
}

code[class*="language-"],
pre[class*="language-"] {
	color: #f8f8f2;
	background: none;
	text-shadow: 0 1px rgba(0, 0, 0, 0.3);
	font-family: monospace;
	font-size: 0.9rem;
	line-height: 1.5;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
	border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #253238;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .1em;
	border-radius: .3em;
	white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: slategray;
}

.token.punctuation {
	color: #89ddfd;
}

.namespace {
	opacity: .7;
}

.token.property,
.token.constant,
.token.symbol,
.token.deleted {
	color: #b2ccd6;
}

.token.tag {
	color: #f07178;
}

.token.boolean,
.token.number {
	color: #f78661;
}

.token.selector,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #c3e076;
}

.token.attr-name {
	color: #fecb68;
	font-style: italic;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
	color: #89ddfd;
}

.token.variable {
	color: #eeffff;
}

.token.atrule,
.token.function {
	color: #82aafc;
}

.token.attr-value {
	color: #c4e88d;
}

.token.keyword {
	color: #c792ea;
}

.token.regex,
.token.important {
	color: #fd971f;
}

.token.important,
.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}


.token.comment,
.token.keyword {
  font-style: italic;
}

/* -- Line-numbers plugin css */

pre.line-numbers {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;
}

pre.line-numbers > code {
	position: relative;
}

.line-numbers .line-numbers-rows {
	position: absolute;
	pointer-events: none;
	top: 0;
	font-size: 100%;
	left: -3.8em;
	width: 3em; /* works for line-numbers below 1000 lines */
	letter-spacing: -1px;
	border-right: 1px solid #444;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}

	.line-numbers-rows > span {
		pointer-events: none;
		display: block;
		counter-increment: linenumber;
	}

		.line-numbers-rows > span:before {
			content: counter(linenumber);
			color: #555;
			display: block;
			padding-right: 0.8em;
			text-align: right;
		}