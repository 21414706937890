/* Checkmark style starts */
@-moz-keyframes
dothabottomcheck {
  0% {
    height: 0;
  }
 100% {
    height: 4vw;
  }
}

@-webkit-keyframes
dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 4vw;
  }
}

@keyframes
dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 4vw;
  }
}

@keyframes
dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 9.6vw;
  }
}

@-webkit-keyframes
dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 9.6vw;
  }
}

@-moz-keyframes
dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 9.6vw;
  }
}

input[type=checkbox] { display: none; }

.check-box {
  height: 8vw;
  width: 8vw;
  background-color: transparent;
  border: 0.9vw solid #58595b;
  border-radius: 0.5vw;
  position: relative;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: border-color ease 0.2s;
  -o-transition: border-color ease 0.2s;
  -webkit-transition: border-color ease 0.2s;
  transition: border-color ease 0.2s;
  cursor: pointer;
}

.check-box::before,
.check-box::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  height: 0;
  width: 1.6vw;
  background-color: #8dcec1;
  display: inline-block;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 0.4vw;
  content: ' ';
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
  transition-timing-function: ease;
}

.check-box::before {
  top: 5.5vw;
  left: 3.3vw;
  box-shadow: 0 0 0 0.45vw #FFF;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.check-box::after {
  top: 2.7vw;
  left: 0.4vw;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type=checkbox]:checked + .check-box,
.check-box.checked { border-color: #8dcec1; }

input[type=checkbox]:checked + .check-box::after,
.check-box.checked::after {
  height: 4vw;
  -moz-animation: dothabottomcheck 0.2s ease 0s forwards;
  -o-animation: dothabottomcheck 0.2s ease 0s forwards;
  -webkit-animation: dothabottomcheck 0.2s ease 0s forwards;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}

input[type=checkbox]:checked + .check-box::before,
.check-box.checked::before {
  height: 9.6vw;
  -moz-animation: dothatopcheck 0.4s ease 0s forwards;
  -o-animation: dothatopcheck 0.4s ease 0s forwards;
  -webkit-animation: dothatopcheck 0.4s ease 0s forwards;
  animation: dothatopcheck 0.4s ease 0s forwards;
}

/* --------------------------------------------------------------------------------
@media Large devices (large desktops, 1200px and up)
-------------------------------------------------------------------------------- */

@media only screen and (min-width: 75em) {

  @-moz-keyframes
  dothabottomcheck {
    0% {
      height: 0;
    }
   100% {
      height: 48px;
    }
  }

  @-webkit-keyframes
  dothabottomcheck {
    0% {
      height: 0;
    }
    100% {
      height: 48px;
    }
  }

  @keyframes
  dothabottomcheck {
    0% {
      height: 0;
    }
    100% {
      height: 48px;
    }
  }

  @keyframes
  dothatopcheck {
    0% {
      height: 0;
    }
    50% {
      height: 0;
    }
    100% {
      height: 115.2px;
    }
  }

  @-webkit-keyframes
  dothatopcheck {
    0% {
      height: 0;
    }
    50% {
      height: 0;
    }
    100% {
      height: 115.2px;
    }
  }

  @-moz-keyframes
  dothatopcheck {
    0% {
      height: 0;
    }
    50% {
      height: 0;
    }
    100% {
      height: 115.2px;
    }
  }
  .check-box {
    height: 96px;
    width: 96px;
    background-color: transparent;
    border: 10.8px solid #58595b;
    border-radius: 6px;
  }

  .check-box::before,
  .check-box::after {
    width: 19.2px;
    border-radius: 4.8px;
  }

  .check-box::before {
    top: 66px;
    left: 39.6px;
    box-shadow: 0 0 0 5.4px #FFF;
  }

  .check-box::after {
    top: 32.4px;
    left: 4.8px;
  }

  input[type=checkbox]:checked + .check-box::after,
  .check-box.checked::after {
    height: 48px;
  }

  input[type=checkbox]:checked + .check-box::before,
  .check-box.checked::before {
    height: 115.2px;
  }

}